import React from 'react'

const Spinner = () => (
  <div className="sk-circle">
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
)

export default Spinner
